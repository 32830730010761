import * as React from "react";
import {useEffect, useMemo, useRef, useState} from "react";
import {Button, Card, CardActions, CardContent, CardMedia, Stack, Table, TableBody, Typography} from "@mui/material";
import {useBookingContext} from "../../../context/BookingContext";
import {displayAbsoluteDate, displayAbsoluteTime, displayMoney} from "../../../utils/DisplayUtils";
import api from "../../../utils/api";
import {usePriceCalculator} from "../../../utils/usePriceCalculator";
import {useReferenceContext} from "../../../context/ReferenceContext";
import {FlowStepProps, StepIndex} from "../BookingFlow";
import {useAlertMessageContext} from "../../../context/AlertMessageContext";
import Section from "./Section";
import Item from "./Item";
import CostTableRow from "./CostTableRow";

export default function Confirmation({onPrevPage, onChangePage}: FlowStepProps) {
    const {
        booking,
        days,
        location,
        vehicle_type,
        subscription_plan,
        vehicle_age,
        vehicle_category,
        vehicle_subcategory,
        updateBooking
    } = useBookingContext()

    const {reference} = useReferenceContext()

    const priceCalculator = usePriceCalculator()

    const alertMessageContext = useAlertMessageContext()

    const [price, setPrice] = useState(priceCalculator.getBookingPrice)

    const [submitting, setSubmitting] = useState(false)

    const ref = useRef<HTMLDivElement>()

    const createRequest = useMemo(() => {
        if (!booking.id &&
            booking.address &&
            booking.dob &&
            booking.email &&
            booking.end_date &&
            booking.mobile &&
            booking.title &&
            booking.first_name &&
            booking.last_name &&
            booking.suburb &&
            booking.state &&
            booking.postcode &&
            booking.start_date &&
            booking.subscription_plan_id &&
            booking.vehicle_age_id &&
            booking.vehicle_type_id &&
            booking.location_id
        ) {
            return {
                address: booking.address,
                dob: booking.dob,
                email: booking.email,
                end_date: booking.end_date,
                extras: booking.extras || [],
                mobile: booking.mobile,
                title: booking.title,
                first_name: booking.first_name,
                middle_name: booking.middle_name,
                last_name: booking.last_name,
                suburb: booking.suburb,
                state: booking.state,
                postcode: booking.postcode,
                start_date: booking.start_date,
                subscription_plan_id: booking.subscription_plan_id,
                vehicle_age_id: booking.vehicle_age_id,
                vehicle_type_id: booking.vehicle_type_id,
                location_id: booking.location_id,
                add_to_mailing_list: !!booking.add_to_mailing_list
            }
        } else {
            console.log("booking", booking)
            return null
        }
    }, [booking])

    let damage_liability = (vehicle_subcategory?.damage_liability || vehicle_category?.damage_liability || 0)
    // TODO Move into price calculator in ef-shared?
    booking.extras?.forEach(bve => {
        const vehicleExtra = reference.vehicle_extras.find(ve => ve.id === bve.vehicle_extra_id)
        if (vehicleExtra?.damage_liability_reduction) {
            damage_liability = Math.max(damage_liability - vehicleExtra.damage_liability_reduction, 0)
        }
    })

    function onConfirm() {
        setSubmitting(true)
        api.booking.createBooking(createRequest!).then(response => {
            updateBooking({id: response.data})
            window.parent.postMessage("scrollToTop", "*")
        }).catch((error) => {
            alertMessageContext.error(error, "Error sending request")
        }).finally(() => setSubmitting(false))
    }

    useEffect(() => {
        if (createRequest) {
            api.booking.getPrice(createRequest).then(response => {
                setPrice(response.data)
            })
        }
    }, [createRequest, onPrevPage])

    if (!price || !vehicle_type || !location || !subscription_plan || !vehicle_age) {
        return null
    }

    const changeButtonHandler = (step: StepIndex) => {
        if (booking.id) {
            return undefined
        }
        return () => onChangePage(step)
    }

    return (
        <Card sx={{
            backgroundColor: '#F6F7F8',
            paddingBottom: '10px',
            border: '2px solid #d3d3d3',
            borderRadius: '5px'
        }}>
            <Stack ref={ref} direction="row">
                <CardContent sx={{width: "100%"}}>
                    <Typography variant="h5">
                        Subscription Request{booking.id ? " Confirmation" : ""}
                    </Typography>
                    <Typography sx={{mb: 2}} gutterBottom
                                variant="body1">{!booking.id ? "Please check your subscription details" : <>Thank you.
                        Your Subscription request has been received, an Everything Fleet team member will be in touch
                        shortly.<br/>Please contact us on 131 381 if you need further assistance.</>}</Typography>
                    <Stack direction="row">
                        <Typography sx={{color:'#1e5e9d', fontWeight:'bold', mr:1}}>Booking Reference</Typography>
                        <Typography>{booking.id}</Typography>
                    </Stack>
                    <Section title="Plan Options" number={1}
                             onButton={changeButtonHandler(StepIndex.PLAN_OPTIONS)}>
                        <Item label="Pick up" value1={displayAbsoluteDate(booking.start_date)}
                              value2={`${displayAbsoluteTime(booking.start_date)} at ${location.name}`}/>
                        <Item label="Drop off" value1={displayAbsoluteDate(booking.end_date)}
                              value2={location.name}/>
                        <Item label="Hire Days" value1={days} noBoldValue1WhenBookingProcessed={true}/>
                        divider
                        <CardMedia
                            sx={{objectFit: "contain", minWidth:0, maxHeight:"160px", minHeight: '10px'}}
                            component="img"
                            image={`/public/vehicles/${vehicle_type.sipp_code}.png`}
                            alt={vehicle_type.sipp_code}
                        />

                        <Item label="Vehicle" value1={`${vehicle_type.name} (${vehicle_type.sipp_code})`} noBoldValue1WhenBookingProcessed={true}/>

                        <>
                            <Item label="Subscription Plan" value1={subscription_plan.name} noBoldValue1WhenBookingProcessed={true}/>
                            <Item label="Vehicle Age" value1={vehicle_age.name} noBoldValue1WhenBookingProcessed={true}/>
                        </>
                    </Section>
                    <Section title="Vehicle Options" number={2}
                             onButton={changeButtonHandler(StepIndex.VEHICLE_OPTIONS)}>
                        {booking.extras?.map((e, i) => {
                            const extra = reference.vehicle_extras.find(ve => ve.id === e.vehicle_extra_id)
                            const extraPrice = price.extras_prices.find(ep => ep.vehicle_extra_id === e.vehicle_extra_id)
                            if (extra && e.count && extraPrice) {
                                return <Item key={e.vehicle_extra_id}
                                             label={extra.name + ((e.count > 1) ? ` (x${e.count})` : '')}
                                             value2={displayMoney(extraPrice.total_price)}/>
                            } else {
                                return null
                            }
                        }) || <Typography>No extras selected</Typography>}
                    </Section>
                    <Section title="Personal Details" number={3}
                             onButton={changeButtonHandler(StepIndex.PERSONAL_OPTIONS)}>
                        <>
                            <Item label="Hirer Name"
                                  value2={[booking.title, booking.first_name, booking.middle_name, booking.last_name].filter(x => !!x).join(' ')}/>
                            <Item label="Address"
                                  value2={<>{booking.address}<br/>{booking.suburb} {booking.state} {booking.postcode}</>}/>
                        </>
                        <>
                            <Item label="Mobile" value2={booking.mobile}/>
                            <Item label="Email" value2={booking.email}/>
                        </>
                        <Item label="DOB" value2={displayAbsoluteDate(booking.dob, true)}/>
                    </Section>
                    <Section title="Summary of charges" grid={false} divider={false}>
                        <Table size="small">
                            <TableBody>
                                <CostTableRow sx={{paddingBottom:3}} label="Damage Liability" price={damage_liability}/>

                                <CostTableRow label="Annual Fee" price={price.annual_fee}/>
                                <CostTableRow label="Subscription Fee" price={price.vehicle_price.total_price}/>
                                <CostTableRow label="Extras" price={price.total_extras.total_price}/>
                                <CostTableRow total={true} label="TOTAL COSTS (inc. GST)" price={price.total.total_price}/>
                            </TableBody>
                        </Table>
                    </Section>
                </CardContent>

            </Stack>
            {!booking.id &&
                <CardActions sx={{justifyContent: "flex-end"}}>
                    <Button sx={{
                        borderRadius:50,
                        transform:'scale(1.3)',
                        mr:3,
                        '&.MuiButton-root:hover':{backgroundColor: '#071423'},
                        backgroundColor: '#198fd9'
                    }} disabled={submitting || !createRequest} size="small" variant="contained"
                            onClick={onConfirm}>Send
                        Request</Button>
                </CardActions>
            }
        </Card>
    );
}