import * as React from 'react'
import {PropsWithChildren, useCallback, useContext, useEffect, useState} from 'react'
import usePersistentState from "../utils/usePersistentState";
import {
    CreateSubscriptionBooking,
    HirerAge,
    Location,
    SubscriptionPlan,
    VehicleAge,
    VehicleCategory,
    VehicleSubcategory,
    VehicleType
} from "../generated/Api";
import dayjs from "dayjs";
import {useReferenceContext} from "./ReferenceContext";
import BookingSchema from "./BookingSchema";
import usePrevious from "../utils/usePrevious";

export interface Booking extends Partial<CreateSubscriptionBooking> {
    vehicle_category_id?: number
    subscription_hire_duration_id?: number
    hirer_age_id?: number
    id?: number
}

export type BookingKey = keyof Booking;

export type Errors = {
    [key in BookingKey]?: string;
};

interface BookingContextType {
    booking: Booking
    errors: Errors
    subscription_plan?: SubscriptionPlan,
    hirer_age?: HirerAge,
    vehicle_type?: VehicleType,
    vehicle_age?: VehicleAge,
    vehicle_category?: VehicleCategory,
    vehicle_subcategory?: VehicleSubcategory,
    location?: Location
    isValidForFields(fields:BookingKey[]):boolean
    updateBooking: (d: Booking) => void
    validPlanIds: number[]
    days: number
}

const DEFAULT_BOOKING: Booking = {}

export const BookingContext = React.createContext<BookingContextType>(undefined!)

export function useBookingContext() {
    return useContext(BookingContext)
}


const BookingContextProvider = (props: PropsWithChildren<{}>) => {
    const {reference} = useReferenceContext()
    const [booking, setBooking] = usePersistentState<Booking>("booking", DEFAULT_BOOKING)

    const updateBooking = useCallback((newBookingData: Booking) => {
        console.log("updateBooking", newBookingData)
        setBooking(existingBooking => {
            if (existingBooking.id) {
                return existingBooking
            } else {
                return {...existingBooking, ...newBookingData}
            }
        })
    }, [setBooking])

    const [errors, setErrors] = useState<Errors>({})

    useEffect(() => {
        if (booking.id) {
            console.log("Removing booking from local storage")
            // localStorage.removeItem("booking")
            localStorage.setItem("booking", JSON.stringify({
                title:booking.title,
                first_name:booking.first_name,
                middle_name:booking.middle_name,
                last_name:booking.last_name,
                address:booking.address,
                suburb:booking.suburb,
                state:booking.state,
                postcode:booking.postcode,
                mobile:booking.mobile,
                email:booking.email,
                dob:booking.dob
            }))
        }
    }, [booking])

    useEffect(() => {
        BookingSchema.validate(booking, { abortEarly: false }).then(function() {
            setErrors({})
        }).catch(function (err) {
            let errors:Errors = {}
            err.inner.forEach((e:any) => {
                if (e.path) {
                    errors[e.path as BookingKey] = e.message
                }
            });
            setErrors(errors)
        });
    }, [booking])

    function isValidForFields(fields:string[]):boolean {
        return !fields.find(f => Object.keys(errors).includes(f))
    }

    const days = booking.start_date && booking.end_date ? dayjs(booking.end_date).diff(dayjs(booking.start_date).startOf('day'), 'days') : 0
    const validPlanIds = reference.subscription_plans.filter(p => days >= p.min_days).sort((p1, p2) => p2.min_days - p1.min_days).map(p => p.id)
    const previousValidPlanIds = usePrevious(validPlanIds)
    const subscription_plan = reference.subscription_plans.find(sp => sp.id === booking.subscription_plan_id)
    const hirer_age = reference.hirer_ages.find(ha => ha.id === booking.hirer_age_id)
    const vehicle_type = reference.vehicle_types.find(vt => vt.id === booking.vehicle_type_id)
    const vehicle_age = reference.vehicle_ages.find(vt => vt.id === booking.vehicle_age_id)
    const vehicle_category = reference.vehicle_categories.find(vc => vc.id === booking.vehicle_category_id)
    const vehicle_subcategory = vehicle_type ? reference.vehicle_subcategories.find(vs => vs.id === vehicle_type.vehicle_subcategory_id) : undefined
    const location = reference.locations.find(l => l.id === booking.location_id)

    useEffect(() => {
        if (validPlanIds?.length && (!booking.subscription_plan_id || previousValidPlanIds?.length !== validPlanIds.length || !validPlanIds.includes(booking.subscription_plan_id))) {
            updateBooking({subscription_plan_id: validPlanIds[0]})
        }
        if (!booking.vehicle_age_id && reference.vehicle_ages?.length) {
            updateBooking({vehicle_age_id: reference.vehicle_ages[0].id})
        }
    }, [booking, reference, validPlanIds, previousValidPlanIds, updateBooking])

    const context = {
        booking,
        errors,
        updateBooking,
        isValidForFields,
        validPlanIds,
        subscription_plan,
        hirer_age,
        vehicle_type,
        vehicle_age,
        vehicle_category,
        vehicle_subcategory,
        location,
        days
    }

    return (
        <BookingContext.Provider value={context}>
            {props.children}
        </BookingContext.Provider>
    )
}

export default BookingContextProvider
