import * as React from "react";
import {FlowStepProps} from "../BookingFlow";
import {Box, Button, Stack} from "@mui/material";
import VehicleOptionsForm from "./VehicleOptionsForm";
import {useReferenceContext} from "../../../context/ReferenceContext";

export default function VehicleOptions({onNextPage}: FlowStepProps) {
    const {reference} = useReferenceContext()

    return (
        <Box>
            {reference.vehicle_extra_categories.map(vec => <VehicleOptionsForm key={vec.id} vehicleExtraCategory={vec}/>)}
            <Stack direction="row" justifyContent="right"><Button sx={{mt:1}} onClick={onNextPage} variant="contained">Next Page</Button></Stack>
        </Box>
    );
}