import Typography from "@mui/material/Typography";
import * as React from "react";
import {TableCell, TableRow} from "@mui/material";
import {displayMoney} from "../../../utils/DisplayUtils";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {useBookingContext} from "../../../context/BookingContext";

interface CostTableRowProps {
    label: string
    price: number
    total?: boolean

    sx?:SxProps<Theme>
}

export default function CostTableRow({label, price, total, sx}: CostTableRowProps) {
    const {booking} = useBookingContext()
    const bookingProcessed = !!booking.id

    const totalSx:SxProps<Theme> = total ? {color:bookingProcessed ? undefined : '#1e5e9d', fontWeight:'bold'} : {}
    const cellSx={borderBottom:0, padding:0, ...sx}
    return (
        <TableRow>
            <TableCell sx={cellSx}>
                <Typography sx={totalSx}>{label}</Typography>
            </TableCell>
            <TableCell sx={cellSx}>
                <Typography sx={{textAlign:'right', ...totalSx}}>{displayMoney(price)}</Typography>
            </TableCell>
        </TableRow>
    );
}