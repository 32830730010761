import React from 'react'
import useFormField, {UseFormFieldProps} from 'components/form/useFormField'
import {Checkbox, FormControlLabel} from "@mui/material";

interface FormFieldCheckboxProps extends UseFormFieldProps {
  label?: string
  error?: string
  disabled?: boolean
}

const FormFieldCheckbox = (props:FormFieldCheckboxProps) => {
  const {label, disabled} = props

  const {value, setValue} = useFormField(props)

  return (
      <FormControlLabel
          control={<Checkbox
              color="primary"
              disabled={disabled}
              checked={value}
              onChange={(e)=>setValue(e.target.checked)}
          />}
          label={label}
      />
  )
}

export default FormFieldCheckbox
