import * as Yup from 'yup'
import {setLocale, StringSchema} from 'yup'
import emailAddresses, {ParsedGroup, ParsedMailbox} from 'email-addresses'

setLocale({
  mixed: {
    required: "This field is required",
  },
})

const isMailbox = (variableToCheck: ParsedMailbox|ParsedGroup): variableToCheck is ParsedMailbox =>
    !!((variableToCheck as ParsedMailbox).address)

interface EmailAddress {
  name: string|null
  email: string
}

function stringToEmailAddress(string?:string):EmailAddress|undefined {
  const parsed = string ? emailAddresses.parseOneAddress(string) : undefined
  return (parsed && isMailbox(parsed)) ? {name:parsed.name, email:parsed.address} : undefined
}

declare module 'yup' {
  interface StringSchema {
    isEmail(): StringSchema
  }
}

Yup.addMethod<StringSchema>(Yup.string, 'isEmail', function() {
  return this.test('email', (x) => " " + x.value + " is not an email", (value) => !!stringToEmailAddress(value))
});
