import Typography from "@mui/material/Typography";
import * as React from "react";
import {Stack, styled, ToggleButton, ToggleButtonGroup, useMediaQuery, useTheme} from "@mui/material";
import {useBookingContext} from "../../../context/BookingContext";
import {useReferenceContext} from "../../../context/ReferenceContext";

const VehicleAgeToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        border: '1px solid #d3d3d3 !important',
        borderRadius: 0,
        '&.Mui-selected': {
            color: '#1268b3',
            backgroundColor: '#f6f7f8',
            fontWeight: 600
        },
    },
}));

export default function SubscriptionPlanBlock() {
    const { booking, ...bookingContext } = useBookingContext()
    const { reference } = useReferenceContext()
    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Stack alignItems={mobileDevice ? "flex-start" : "flex-end"}>
            <VehicleAgeToggleButtonGroup
                size="small"
                value={booking.vehicle_age_id}
                exclusive
                onChange={(e,v)=> {
                    if (v) {
                        bookingContext.updateBooking({vehicle_age_id:v})
                    }
                }}
                aria-label="Type"
                sx={{mb:1}}
            >
                {reference.vehicle_ages.map(p =>
                    <ToggleButton key={p.id} value={p.id}>{p.name}</ToggleButton>
                )}
            </VehicleAgeToggleButtonGroup>
            {bookingContext.vehicle_age && <Typography sx={{color:'#1268b3'}} variant="body2">{bookingContext.vehicle_age.description}</Typography>}
        </Stack>
    );
}