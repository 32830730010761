import React, {ReactNode} from "react";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime)

export function displayMoney(val?: number):string {
  if (val == null) {
    return ''
  }
  return new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(val)
}

export function plural(str:string, num:number):string {
  return num === 1 ? str : str + "s"
}

export function fileSize(lengthInBytes:number):ReactNode {
  let unit:string=''
  let value=lengthInBytes
  if (value == null) {
    return null
  } else if (value > 1024*1024*1024*1024) {
    unit = 'TB'
    value = Math.round((value * 10) / (1024*1024*1024*1024))/10
  } else if (value > 1024*1024*1024) {
    unit = 'GB'
    value = Math.round((value * 10) / (1024*1024*1024))/10
  } else if (value > 1024*1024) {
    unit=' MB'
    value = Math.round((value * 10) / (1024*1024))/10
  } else if (value > 1024) {
    unit = 'k'
    value = Math.round(value/1024)
  } else {
    unit = ' bytes'
  }
  return <span title={lengthInBytes + ' bytes'}>{value}{unit}</span>
}

interface SelectOption {
  value:string
  label:string
}

export function enumOptions<T extends object>(obj:T):SelectOption[] {
  return Object.keys(obj).map(o => ({value:o, label:displayLabel(o)}))
}

export function displayLabel(enumValue:string):string {
  if (!enumValue) {
    return ''
  }
  let frags = enumValue.toLowerCase().split('_');
  for (let i=0; i<frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

export function displayAbsoluteDate(dateString:dayjs.ConfigType, short:boolean = false):string {
  if (!dateString) {
    return ''
  }
  return dayjs(dateString).format(short ? 'DD/MM/YYYY' : 'dddd, MMMM D, YYYY')
}

export function displayAbsoluteDateTime(dateString:dayjs.ConfigType):string {
  if (!dateString) {
    return ''
  }
  return dayjs(dateString).format(`dddd, MMMM D, YYYY h:mm A`)
}

export function displayAbsoluteTime(dateString:dayjs.ConfigType):string {
  if (!dateString) {
    return ''
  }
  return trimEmptyMinutes(dayjs(dateString).format('h:mma'))
}

export function trimEmptyMinutes(formattedString:string):string {
  return formattedString.replace(/:00/g, '')
}

export function displayAbsoluteDateTimeRange(startDateString:dayjs.ConfigType, endDateString:dayjs.ConfigType):string {
  const firstDate = displayAbsoluteDateTime(startDateString)
  const sameDay = dayjs(startDateString).isSame(endDateString, 'day')
  return firstDate + " - " + (sameDay ? displayAbsoluteTime(endDateString) : displayAbsoluteDateTime(endDateString))
}

export function displayDate(dateString:dayjs.ConfigType) {
  if (!dateString) {
    return ''
  }
  return dayjs(dateString).fromNow()
}
