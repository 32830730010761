import React, {useEffect, useRef, useState} from 'react'
import useFormField, {UseFormFieldProps} from 'components/form/useFormField'
import {InputAdornment, TextField} from "@mui/material";
import {BookingKey} from "../../context/BookingContext";

interface FormFieldTextProps extends UseFormFieldProps {
  name: BookingKey
  type?: 'text'|'percent'|'number'
  label?: string
  error?: string
  disabled?: boolean
}

const FormFieldText = (props:FormFieldTextProps) => {
  const {name, showErrors, label, type = "text", disabled} = props

  const ref = useRef<HTMLInputElement>()

  const {hasError, errorText, value, setValue} = useFormField({name, showErrors, ref})

  const [fieldValue, setFieldValue] = useState(value || '')
    useEffect(()=> {
        setFieldValue(value || '')
    }, [value])

  return (
      <TextField
          inputRef={ref}
          size="small"
          fullWidth
          label={label}
          type={type === 'percent' ? 'number' : type}
          disabled={disabled}
          variant="outlined"
          value={fieldValue}
          onChange={(e)=>setFieldValue(e.target.value)}
          onBlur={()=>setValue(fieldValue)}
          error={hasError}
          helperText={hasError && errorText}
          InputProps={type === 'percent' ? {
            inputProps: {
              min: 0,
              max: 100
            },
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          } : undefined}
      />
  )
}

export default FormFieldText
