import * as React from 'react'
import {Dispatch, SetStateAction, useRef} from 'react'
import {useEffect} from 'react'

function getJsonFromStorage(storageKey:string, initialValue:any) {
    const value = localStorage.getItem(storageKey)
    try {
        if (value) {
            const storageValue = JSON.parse(value)
            // console.log(`Fetched ${JSON.stringify(storageValue)} from ${storageKey}`)
            return storageValue
        } else {
            // console.log(`Nothing found in ${storageKey}`)
        }
    } catch(e) {
        console.log(`Can't parse '${value}' as JSON (looking up ${storageKey})`)
    }
    return initialValue
}

const usePersistentState = <S>(storageKey:string,initialValue:S):[S, Dispatch<SetStateAction<S>>] => {
    const [value, setValue] = React.useState<S>(() => getJsonFromStorage(storageKey, initialValue));
    const initialValueRef = useRef(initialValue)

    useEffect(() => {
        initialValueRef.current = initialValue
    }, [initialValue])

    useEffect(()=> {
        setValue(getJsonFromStorage(storageKey, initialValueRef.current))
    }, [storageKey])

    useEffect(() => {
        localStorage.setItem(storageKey, JSON.stringify(value));
    }, [value, storageKey]);
    return [value, setValue];
};

export default usePersistentState