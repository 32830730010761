import * as React from "react";
import {useState} from "react";
import {Box, Button, Grid} from "@mui/material";
import {useBookingContext} from "../../../context/BookingContext";
import FormFieldText from "../../../components/form/FormFieldText";
import FormFieldDate from "../../../components/form/FormFieldDate";
import dayjs from "dayjs";
import FormFieldCheckbox from "../../../components/form/FormFieldCheckbox";
import FormFieldSelect from "../../../components/form/FormFieldSelect";

interface BookingStripFormProps {
    external?:boolean
    onSubmit?:()=>void
}

export default function PersonalDetailsForm({onSubmit}: BookingStripFormProps) {
    const {booking, updateBooking, isValidForFields,  ...bookingContext} = useBookingContext()

    const [submitCount, setSubmitCount] = useState(0)
    const showErrors = submitCount > 0

    const valid = isValidForFields(['title', 'middle_name', 'first_name', 'last_name', 'address', 'suburb', 'state', 'postcode', 'mobile', 'email', 'dob'])

    function submitForm() {
        setSubmitCount(sc => sc+1)
        if (valid && onSubmit) {
            onSubmit()
        }
    }

    const maxDate = bookingContext.hirer_age?.min_age ? dayjs(booking.start_date).add(-bookingContext.hirer_age.min_age, "years") : dayjs()
    const minDate = bookingContext.hirer_age?.max_age ? dayjs(booking.start_date).add(-bookingContext.hirer_age.max_age, "years") : null

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={6}><FormFieldSelect name="title" label="Title" options={["Mr", "Mrs", "Miss", "Ms", "Dr"]} isClearable={true} allowOther={true} showErrors={showErrors}/></Grid>
            <Grid item xs={6}><FormFieldText name="first_name" label="First Name" showErrors={showErrors}/></Grid>
            <Grid item xs={6}><FormFieldText name="middle_name" label="Middle Name" showErrors={showErrors}/></Grid>
            <Grid item xs={6}><FormFieldText name="last_name" label="Last Name" showErrors={showErrors}/></Grid>
            <Grid item xs={12}><FormFieldText name="address" label="Residential Address" showErrors={showErrors}/></Grid>
            <Grid item xs={6}><FormFieldText name="suburb" label="Suburb" showErrors={showErrors}/></Grid>
            <Grid item xs={6}><FormFieldSelect name="state" label="State" options={["QLD", "NSW", "VIC", "WA", "TAS", "ACT", "NT"]} showErrors={showErrors}/></Grid>
            <Grid item xs={6}><FormFieldText name="postcode" label="Postcode" showErrors={showErrors}/></Grid>
            <Grid item xs={6}><FormFieldText name="mobile" label="Mobile" showErrors={showErrors}/></Grid>
            <Grid item xs={6}><FormFieldText name="email" label="Email" showErrors={showErrors}/></Grid>
            <Grid item xs={6}><FormFieldDate name="dob" label="Date of Birth" minDate={minDate} maxDate={maxDate} type="dob" showErrors={showErrors}/></Grid>
            <Grid item xs={12}><FormFieldCheckbox name="add_to_mailing_list" label="Please add me to your mailing list for specials and offers" showErrors={showErrors}/></Grid>
            <Grid item xs={12}><Box display="flex" justifyContent="flex-end"><Button
                disabled={showErrors && !valid}
                onClick={submitForm}
                variant="contained"
            >Next Page</Button></Box></Grid>
        </Grid>
    );
}