import * as React from "react";
import {useBookingContext} from "../../context/BookingContext";
import VehicleBox from "../../components/VehicleBox/VehicleBox";
import {usePriceCalculator} from "../../utils/usePriceCalculator";

export default function BookingSummary() {
    const {booking, ...bookingContext} = useBookingContext()
    const priceCalculator = usePriceCalculator()
    const vehicle = bookingContext.vehicle_type

    if (!vehicle) {
        return null
    }

    const bookingPrice = priceCalculator.getBookingPrice()

    return (
        <VehicleBox vehicle={vehicle} subscription_plan={bookingContext.subscription_plan} vehicle_age={bookingContext.vehicle_age} vehiclePrice={bookingPrice.vehicle_price} bookingPrice={bookingPrice} days={priceCalculator.days}/>
    );
}