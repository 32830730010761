import React from 'react'
import {Table, TableBody} from "@mui/material";

export default function PropertyList(props) {
    return (
        <div>
            <Table size="small">
                <TableBody>
                  {props.children}
                </TableBody>
            </Table>
        </div>
    );
}
