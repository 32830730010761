import React from 'react'
import Typography from "@mui/material/Typography";
import {TableCell, TableRow} from "@mui/material";

export default function PropertyListRow(props) {
    const { label, variant } = props;

    let labelOutput = <strong>{label}</strong>
    let valueOutput = props.children
    let extraStyle = {borderBottom:"none"}
    if (variant === 'compact') {
      labelOutput = <Typography variant="body1">{labelOutput}</Typography>
      valueOutput = <Typography sx={{textAlign:'right'}} variant="body1">{valueOutput}</Typography>
      extraStyle = {...extraStyle, paddingTop:0, paddingBottom:0}
    }
    return (
        <TableRow>
            <TableCell component="th" scope="row" style={{width: '1px', whiteSpace: 'nowrap', ...extraStyle}}>
              {labelOutput}
            </TableCell>
            <TableCell style={extraStyle}>
              {valueOutput}
            </TableCell>
        </TableRow>
    );
}
