import * as React from 'react'
import {PropsWithChildren, useContext, useMemo, useState} from 'react'
import * as Sentry from '@sentry/react'
import {Alert, Snackbar} from "@mui/material";
import {AlertColor} from "@mui/material/Alert/Alert";

type AlertMessageContextValue = {
  alert: (severity:AlertColor, message:string) => void,
  success: Function,
  error: Function
}

export const AlertMessageContext = React.createContext<AlertMessageContextValue|undefined>(undefined)

export function useAlertMessageContext() {
  return useContext(AlertMessageContext)!
}

interface SnackMessage {
  severity: AlertColor
  message: string
  open: boolean
}

const AlertMessageContextProvider = (props : PropsWithChildren<{}>) => {
  const [snackMessage, setSnackMessage] = useState<SnackMessage|null>(null)

  const value = useMemo(()=> {
    function alert(severity:AlertColor, message:string) {
      setSnackMessage({severity, message, open: true})
    }

    function success(message: string) {
      alert('success', message)
    }

    function error(e: any, message: string) {
      const context = e.error?.message
      const fullMessage = message + (context ? ": " + context : "")
      console.error(fullMessage, e)
      Sentry.withScope(scope => {
        scope.setExtra("message", fullMessage)
        scope.setExtra("response", e.error)
        Sentry.captureException(e)
      });
      alert('error', fullMessage)
    }
    return {alert, success, error}
  }, [])

  return (
    <AlertMessageContext.Provider value={value}>
      {props.children}
      {snackMessage && <Snackbar open={snackMessage.open} onClose={()=>setSnackMessage(null)} autoHideDuration={snackMessage.severity === 'success' ? 5000 : null}>
        <Alert severity={snackMessage.severity} onClose={()=>setSnackMessage(null)}>{snackMessage.message}</Alert>
      </Snackbar>}
    </AlertMessageContext.Provider>
  )
}

export default AlertMessageContextProvider
