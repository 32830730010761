import dayjs from "dayjs";
import {useBookingContext} from "../context/BookingContext";
import {SubscriptionPlan, VehicleAge, VehicleExtra, VehicleType} from "../generated/Api";
import {useReferenceContext} from "../context/ReferenceContext";
import {
    BookingPrice,
    calculateBookingPrice,
    calculateExtraPrice,
    calculateVehiclePrice,
    ExtraPrice,
    Price,
    PriceLookupData
} from "@internal/ef-shared";

export interface PriceCalculator {
    getVehiclePrice(vehicle: VehicleType, vehicle_age?: VehicleAge, subscription_plan?: SubscriptionPlan): Price

    getExtraPrice(extra: VehicleExtra, count: number): ExtraPrice

    getBookingPrice(): BookingPrice

    days: number
}

export function usePriceCalculator(): PriceCalculator {
    const {booking, vehicle_type, ...bookingContext} = useBookingContext()
    const {reference} = useReferenceContext()

    const days = (booking.start_date && booking.end_date) ? dayjs(booking.end_date).diff(dayjs(booking.start_date).startOf('day'), 'days') : 0
    const duration = reference.subscription_hire_durations.find(shd => (!shd.min_days || days >= shd.min_days) && (!shd.max_days || days < shd.max_days))

    const lookupData:PriceLookupData = {
        days: days,
        hirer_age: bookingContext.hirer_age,
        location: bookingContext.location,
        subscription_hire_duration: duration,
        subscription_plan: bookingContext.subscription_plan,
        vehicle_age: bookingContext.vehicle_age,
        vehicle_overrides: reference.vehicle_overrides,
        vehicle_type: vehicle_type,
        vehicle_type_min_age: reference.hirer_ages.find(ha => ha.id === vehicle_type?.min_hirer_age_id)?.min_age,
        vehicle_category: reference.vehicle_categories.find(vc => vc.id === vehicle_type?.vehicle_category_id),
        vehicle_subcategory: reference.vehicle_subcategories.find(vsc => vsc.id === vehicle_type?.vehicle_category_id),
    }

    // console.log("lookupData", lookupData)

    function getExtraPrice(extra: VehicleExtra, count: number): ExtraPrice {
        return calculateExtraPrice(extra, count, lookupData)
    }

    function getVehiclePrice(vehicle: VehicleType, vehicle_age?: VehicleAge, subscription_plan?: SubscriptionPlan): Price {
        return calculateVehiclePrice({...lookupData,
            vehicle_type: vehicle,
            vehicle_age: vehicle_age || lookupData.vehicle_age,
            subscription_plan: subscription_plan || lookupData.subscription_plan
        })
    }

    function getBookingPrice(): BookingPrice {
        const selectedExtras = booking.extras?.map(e =>
            ({vehicle_extra:reference.vehicle_extras.find(ve => ve.id === e.vehicle_extra_id), count:e.count}))
            .filter((se): se is { vehicle_extra: VehicleExtra,count: number } => !!se.vehicle_extra) || []
        return calculateBookingPrice(selectedExtras, lookupData)
    }

    return {
        getVehiclePrice, getExtraPrice, getBookingPrice, days
    }
}