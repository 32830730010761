import * as React from 'react';
import {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import FormFieldSelect from "../../components/form/FormFieldSelect";
import FormFieldDate from "../../components/form/FormFieldDate";
import dayjs from "dayjs";
import {Slider, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Booking, useBookingContext} from "../../context/BookingContext";
import {useReferenceContext} from "../../context/ReferenceContext";
import VehicleBox from "../../components/VehicleBox/VehicleBox";
import {usePriceCalculator} from "../../utils/usePriceCalculator";
import {useNavigate} from "react-router-dom";
import {StepIndex} from "../BookingFlow/BookingFlow";
import {toDateString, toDateTimeString} from "../../utils/DateUtils";
import {displayMoney} from "../../utils/DisplayUtils";

export default function QuickQuote() {
    const {reference} = useReferenceContext()
    const {booking, updateBooking, days} = useBookingContext()

    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

    const priceCalculator = usePriceCalculator()

    const navigate = useNavigate();

    const availableVehicles = reference.vehicle_types.flatMap(vt => {
        return reference.subscription_plans.flatMap(sp => {
            if (days < sp.min_days) {
                return []
            } else {
                return reference.vehicle_ages.map(va => {
                    return ({
                        subscription_plan: sp,
                        vehicle_age: va,
                        vehicle_type: vt,
                        price: priceCalculator.getVehiclePrice(vt, va, sp)
                    })
                })
            }
        })
    }).filter(x =>
        x.vehicle_type.vehicle_category_id === booking.vehicle_category_id &&
        x.price.available &&
        x.vehicle_type.base_weekly_price > 0
    ).sort((x1, x2) => x2.price.weekly_price - x1.price.weekly_price)

    const minPrice = availableVehicles.length ? Math.trunc(availableVehicles[availableVehicles.length-1].price.weekly_price / 10) * 10 : 0
    const maxPrice = availableVehicles.length ? Math.trunc(availableVehicles[0].price.weekly_price / 10) * 10 : 0
    
    const [slider, setSlider] = useState(minPrice)

    const visibleVehicles = availableVehicles.filter(vt =>
        vt.price.weekly_price >= slider - 100 && vt.price.weekly_price <= slider + 10
    )

    useEffect(() => {
        if (slider < minPrice) {
            setSlider(minPrice)
        } else if (slider > maxPrice) {
            setSlider(maxPrice)
        }
    }, [slider, minPrice, maxPrice])

    useEffect(() => {
        let update: Booking = {}
        if (!booking.location_id && reference.locations.length) {
            update.location_id = reference.locations[0].id
        }
        if (!booking.vehicle_category_id && reference.vehicle_categories.length) {
            update.vehicle_category_id = reference.vehicle_categories[0].id
        }
        if (!booking.hirer_age_id && reference.hirer_ages.length) {
            update.hirer_age_id = reference.hirer_ages.find(ha => ha.max_age == null)?.id || reference.hirer_ages[0].id
        }
        if (!booking.start_date) {
            let start_date = dayjs().startOf('day').hour(9)
            if (start_date.isBefore(dayjs())) {
                start_date = start_date.add(1, 'day')
            }
            while ([0, 6].includes(start_date.day())) {
                start_date = start_date.add(1, 'day')
            }
            update.start_date = toDateTimeString(start_date)
        }
        if (!booking.subscription_hire_duration_id && reference.subscription_hire_durations.length) {
            update.subscription_hire_duration_id = reference.subscription_hire_durations[0].id
        }
        if (Object.keys(update).length) {
            updateBooking(update)
        }
    }, [booking, updateBooking, reference])

    useEffect(() => {
        const subscription_hire_duration = reference.subscription_hire_durations.find(shd => shd.id === booking.subscription_hire_duration_id)
        if (subscription_hire_duration) {
            updateBooking({end_date: toDateString(dayjs(booking.start_date).add(subscription_hire_duration.min_days || 0, 'days'))})
        }
    }, [booking.subscription_hire_duration_id, booking.start_date, reference.subscription_hire_durations, updateBooking])

    const showErrors = false
    return (
        <Container maxWidth="lg" sx={{my: 4}}>
            <Stack direction={mobileDevice ? "column":"row"} spacing={2} alignItems="flex-start" width="100%">
                <Stack sx={{backgroundColor:'#f7f8f9', border:'2px solid #ddd', borderRadius:1, padding:3}} spacing={2} justifyContent="center" width="16em">
                    <FormFieldSelect name="location_id" label="Pick Up Location" options={reference.locations}
                                     showErrors={showErrors}/>
                    <FormFieldDate type="datetime" label="Pick Up Date" name="start_date" weekdaysOnly={true}
                                   workhoursOnly={true} minDate={dayjs().toISOString()} showErrors={showErrors}/>
                    <FormFieldSelect name="subscription_hire_duration_id" label="Hire Duration"
                                     options={reference.subscription_hire_durations} showErrors={showErrors}/>
                    <FormFieldSelect label="Vehicle Category" name="vehicle_category_id" options={reference.vehicle_categories} showErrors={showErrors}/>
                    <FormFieldSelect label="Age" name="hirer_age_id" options={reference.hirer_ages} showErrors={showErrors}/>
                    <Typography sx={{whiteSpace: "nowrap", mr: 2}} variant="body1">Weekly
                        Budget <strong>{displayMoney(slider)}</strong></Typography>
                    <Slider sx={{color:'#0668b3'}} aria-label="Volume" min={minPrice} max={maxPrice} step={10} value={slider}
                            onChange={(e, value) => setSlider(value as number)}/>
                </Stack>
                <Stack spacing={2} sx={{flex:1}}>
                {visibleVehicles.map((x,i) => <VehicleBox key={i} vehicle={x.vehicle_type} subscription_plan={x.subscription_plan} vehicle_age={x.vehicle_age}
                                                      vehiclePrice={x.price} days={days} onSelect={() => {
                    updateBooking({
                        vehicle_type_id: x.vehicle_type.id,
                        vehicle_category_id: x.vehicle_type.vehicle_category_id,
                        vehicle_age_id: x.vehicle_age.id,
                        subscription_plan_id: x.subscription_plan.id
                    })
                    navigate('/', {state: {step: StepIndex.PERSONAL_OPTIONS}})
                }}/>)}
                </Stack>
            </Stack>
        </Container>
    );
}