import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import BookingStripForm from "../../components/BookingStripForm";
import {useMediaQuery, useTheme} from "@mui/material";
import BookingBox from "../BookingBox";

export default function BookingStrip() {
    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
    if (mobileDevice) {
        return <BookingBox/>
    }

    return (
        <Container maxWidth="lg" sx={{backgroundColor:"transparent"}}>
            <Box sx={{ my: 4, backgroundColor: 'white', padding: '10px', borderRadius: '8px' }}>
                <BookingStripForm external={true}/>
            </Box>
        </Container>
    );
}