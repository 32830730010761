import * as React from "react";
import {Stack} from "@mui/material";
import BookingFlowStep, {Status} from "./BookingFlowStep";

interface BookingFlowStepsProps {
    stepLabels: string[]
    currentStep: number

    setStep: (stepIndex: number) => void

    completed: boolean
}

export default function BookingFlowSteps({stepLabels, currentStep, setStep, completed}: BookingFlowStepsProps) {
    return (
        <Stack direction="row" spacing="1px">
            {stepLabels.map((label,i)=>
                <BookingFlowStep
                    key={i}
                    label={label}
                    number={i+1}
                    status={(i<currentStep || completed) ? Status.COMPLETED : i === currentStep ? Status.ACTIVE : Status.PENDING}
                    onClick={completed ? undefined : ()=>setStep(i)}
                />)}
        </Stack>
    );
}