import * as React from "react";
import {useEffect, useState} from "react";
import Container from "@mui/material/Container";
import BookingStripForm from "components/BookingStripForm";
import {Box, Stack, useMediaQuery, useTheme} from "@mui/material";
import PlanOptions from "./PlanOptions";
import VehicleOptions from "./VehicleOptions/VehicleOptions";
import PersonalDetails from "./PersonalDetails/PersonalDetails";
import Confirmation from "./Confirmation/Confirmation";
import BookingSummary from "./BookingSummary";
import {useBookingContext} from "../../context/BookingContext";
import {useLocation} from "react-router-dom";
import BookingFlowSteps from "./BookingFlowSteps";

export interface FlowStepProps {
    onNextPage: ()=>void
    onPrevPage: ()=>void
    onChangePage: (step:StepIndex)=>void
}

export enum StepIndex {
    PLAN_OPTIONS,
    VEHICLE_OPTIONS,
    PERSONAL_OPTIONS,
    CONFIRMATION
}

export default function BookingLayout() {

    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

    const loc = useLocation()

    const requestedStep = (loc.state?.step || StepIndex.PLAN_OPTIONS) as StepIndex

    const { booking, vehicle_type, location, vehicle_category, subscription_plan, vehicle_age } = useBookingContext()

    const completed = !!booking.id

    const [step, setStep] = useState<StepIndex>(requestedStep)

    useEffect(() => {
        if (completed && step !== StepIndex.CONFIRMATION) {
            setStep(StepIndex.CONFIRMATION)
        } else if (step > 0 && (!vehicle_type || !location || !vehicle_category || !subscription_plan || !vehicle_age)) {
            setStep(StepIndex.PLAN_OPTIONS)
        }
    }, [step, completed, location, subscription_plan, vehicle_age, vehicle_category,vehicle_type])

    useEffect(() => {
        window.parent.postMessage("scrollToTop", "*")
    }, [step])

    const steps = [
        {label:'Plan Options', component:PlanOptions, hideBooking:true},
        {label:'Vehicle Options', component:VehicleOptions},
        {label:'Personal Details', component:PersonalDetails},
        {label:'Subscription Request', component:Confirmation, hideBooking: true, hideStrip:true}
    ];

    const ActiveStep = steps[step]?.component

    return (
        <Container sx={{backgroundColor:"transparent", mt: 5}}>
            <Box sx={{ mt: 5 }}>
            <Stack spacing={5}>
                {!steps[step].hideStrip && <BookingStripForm direction={mobileDevice ? "column" : "row"}/>}
                <BookingFlowSteps completed={completed} stepLabels={steps.map(s => s.label)} currentStep={step} setStep={setStep}/>
                {!steps[step].hideBooking && <BookingSummary/>}
                <ActiveStep
                    onNextPage={()=> {
                        if (step < StepIndex.CONFIRMATION) {
                            setStep(s => s+1)
                        }
                    }}
                    onPrevPage={()=> {
                        if (step > StepIndex.PLAN_OPTIONS) {
                            setStep(s => s-1)
                        }
                    }}
                    onChangePage={setStep}
                />
            </Stack>
            </Box>
        </Container>
    );
}