import * as React from "react";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Stack,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import automatic from "./icons/automatic.svg"
import manual from "./icons/manual.svg"
import awd from "./icons/awd.png"
import door from "./icons/door.svg"
import passengers from "./icons/passengers.svg"
import {SubscriptionPlan, VehicleAge, VehicleType} from "../../generated/Api";
import {displayMoney} from "../../utils/DisplayUtils";
import Box from "@mui/material/Box";
import {BookingPrice, Price} from "@internal/ef-shared";

interface VehicleBoxProps {
    vehicle: VehicleType
    subscription_plan?: SubscriptionPlan
    vehicle_age?: VehicleAge
    onSelect?: () => void
    vehiclePrice: Price
    bookingPrice?: BookingPrice
    days: number
}

export default function VehicleBox({vehicle, subscription_plan, vehicle_age, vehiclePrice, onSelect, bookingPrice}: VehicleBoxProps) {

    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

    const features = [
        {icon: passengers, label: `${vehicle.passengers || 5} Passengers`},
        {icon: vehicle.automatic ? automatic : manual, label: vehicle.automatic ? 'Automatic' : 'Manual'},
        {icon: door, label: `${vehicle.doors || 4} Doors`},
        {icon: awd, label: vehicle.four_wheel_drive ? "4WD" : '2WD'},
    ]

    const {
        weekly_price,
        weekly_discount,
        available
    } = bookingPrice?.vehicle_price || vehiclePrice

    const featuresBlock = <Stack sx={{mt: 1}} justifyContent="center">
        {features.map(f =>
            <Stack key={f.label} direction="row" alignItems="center" justifyContent="flex-start">
                <img alt="Icon" src={f.icon} width={25} height={25}/>
                <Typography sx={{whiteSpace: "nowrap"}} variant="body2">{f.label}</Typography>
            </Stack>
        )}
    </Stack>

    const carBlock = <CardMedia
        sx={{padding: "1em 1em 0 1em", minWidth:0, maxHeight:"160px", objectFit: "contain"}}
        component="img"
        image={`/public/vehicles/${vehicle.sipp_code}.png`}
        alt={vehicle.sipp_code}
    />

    return (
        <Card sx={{
            backgroundColor: '#F7F8F9',
            paddingBottom: '10px',
            border: '2px solid rgb(221, 221, 221)',
            borderRadius: '5px',
            boxShadow: 'none'
        }}>
            <Stack direction="row">
                <CardContent sx={{width: '100%'}}>
                    <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:"bold", fontSize:"1.3rem", lineHeight:"1"}}>
                        {vehicle.name}
                    </Typography>
                    <Typography variant="body2">
                        {vehicle.description}
                    </Typography>
                    {vehicle_age &&
                        <Typography sx={{mt:2}} variant="body2">
                            <strong>Vehicle Age:</strong><br/>{vehicle_age.name}
                        </Typography>
                    }
                    {subscription_plan &&
                        <Typography sx={{mt:2}} variant="body2">
                            <strong>Subscription Plan:</strong><br/>{subscription_plan.name}
                        </Typography>
                    }
                    {mobileDevice && featuresBlock}
                </CardContent>
                {!mobileDevice ? <>{featuresBlock}{carBlock}</> : null}
                <Stack sx={{m: 1, justifyContent: 'space-between', alignItems: 'flex-end'}}>
                    {weekly_discount ?
                        <Typography variant="h6" sx={{mr:4, textDecoration: "line-through"}}>
                            {displayMoney(weekly_price + weekly_discount)}
                        </Typography>
                        : null}
                    <Stack direction="row" alignItems="baseline" justifyContent="flex-end">

                        <Typography variant="h4" sx={{fontWeight: "500", color: weekly_discount ? '#da1c5c' : null, textDecoration:available ? undefined : 'line-through'}}>
                            {displayMoney(weekly_price)}
                        </Typography>
                        <Typography variant="body2" sx={{mr: 1}}>
                            /wk
                        </Typography>
                    </Stack>
                    {bookingPrice &&
                        <>
                            <Typography variant="body2" sx={{mr: 1}}>
                                Annual Fee: <strong>{displayMoney(bookingPrice.annual_fee)}</strong>
                            </Typography>
                            <Typography variant="body2" sx={{mr: 1}}>
                                Extras: <strong>{displayMoney(bookingPrice.total_extras.weekly_price)}</strong> /wk
                            </Typography>
                        </>
                    }
                    {/*<Typography variant="body2" sx={{mr: 1}}>*/}
                    {/*    <strong>{days}</strong> days*/}
                    {/*</Typography>*/}
                    {mobileDevice && carBlock}
                    <Box sx={{flex: 1}}/>
                    {onSelect &&
                        <CardActions>
                            <Button size="small" variant="contained" onClick={onSelect}>Select</Button>
                        </CardActions>
                    }
                </Stack>
            </Stack>
        </Card>
    );
}