import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import BookingStripForm from "../../components/BookingStripForm";
import {Typography} from "@mui/material";

export default function BookingBox() {
    return (
        <Container maxWidth="xs" sx={{backgroundColor:"transparent", transform:"scale(0.875)"}}>
            <Box sx={{ my: 4, backgroundColor: 'rgba(255,255,255, 0.90)', padding: '30px', borderRadius: '8px' }}>
                <Typography variant="h5" sx={{mb:1, fontSize:'1.6rem'}}>FIND A VEHICLE</Typography>
                <BookingStripForm external={true} direction="column"/>
            </Box>
        </Container>
    );
}