import {BookingKey, useBookingContext} from "../../context/BookingContext";
import {MutableRefObject, useCallback, useEffect} from "react";

export interface UseFormFieldProps {
  name: BookingKey
  showErrors?: boolean
  ref?: MutableRefObject<HTMLInputElement | undefined>
}

export interface UseFormFieldType {
  value: any
  errorText?: string
  setValue: (value:any)=>void
  hasError: boolean
}

export default function useFormField({name, showErrors, ref}: UseFormFieldProps):UseFormFieldType {
  const { booking, errors, updateBooking } = useBookingContext()
  const value = booking[name]
  const errorText = errors[name]
  const setValue = useCallback((value:any) => updateBooking({[name]: value}), [name, updateBooking])
  const hasError = !!(showErrors && errorText)

  useEffect(() => {
    const onAutoComplete = () => {
      setValue(ref?.current?.value)
    }
    if (ref) {
      document.addEventListener('onautocomplete', onAutoComplete)
    }
    return () => {
      if (ref) {
        document.removeEventListener('onautocomplete', onAutoComplete)
      }
    }
  }, [setValue, ref])
  return {value, setValue, hasError, errorText}
}
