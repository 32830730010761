import * as React from "react";
import {Box, Button, Grid, Stack, Step, StepLabel, Stepper, styled, Typography} from "@mui/material";
import {useBookingContext} from "../../../context/BookingContext";

interface SectionProps {
    number?: number
    title: string
    onButton?: () => void
    children?: React.ReactNode
    divider?: boolean
    grid?: boolean
}

const StyledStepLabel = styled(StepLabel)({
    "& .MuiStepLabel-label": {
        color: "#a2a2a3",
        fontWeight: 'bold'
    }
});

export default function Section({title, number, onButton, children, divider = true, grid = true}: SectionProps) {
    const {booking} = useBookingContext()
    const bookingProcessed = !!booking.id

    return (
        <Stack>
            {!bookingProcessed &&
            <Stack direction="row" justifyContent="space-between">
                {number ?
                    <Stepper activeStep={-1}>
                        <Step>
                            <StyledStepLabel icon={number}>{title}</StyledStepLabel>
                        </Step>
                    </Stepper> :
                    <Typography sx={{color: '#a2a2a3', fontWeight: 'bold'}}>{title}</Typography>
                }
                {onButton && <Button variant="outlined" onClick={onButton}>Edit</Button>}
            </Stack>
            }
            {grid ?
                <Grid container sx={{mt: 2, pl: 3, pr: 2}}>
                    {React.Children.map(children, (child) => {
                        if (child === "divider") {
                            return <Grid item xs={12} sx={{borderTop: "2px solid #d3d3d3", mt: 1, mb: 2}}/>
                        } else {
                            return <Grid item xs={12} sm={4}>
                                {child}
                            </Grid>
                        }
                    })}
                </Grid> : <Box sx={{mt: 2, ml: 3, mr: 2}}>{children}</Box>
            }
            {divider && <Box sx={{borderTop: "2px solid #d3d3d3", mt: 1, mb: 2}}/>}
        </Stack>
    );
}