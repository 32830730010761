import React from 'react'
import useFormField, {UseFormFieldProps} from 'components/form/useFormField'
import {Autocomplete, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField} from "@mui/material";

interface FormFieldSelectProps extends UseFormFieldProps {
    label?: string
    isClearable?: boolean
    disabled?: boolean
    allowOther?: boolean
    selectValue?: string
    selectLabel?: string
    options: any[]
}

const FormFieldSelect = (props: FormFieldSelectProps) => {
    const {label, options, isClearable = false, allowOther = false, selectValue = "id", selectLabel = "name"} = props

    const {value, setValue, hasError, errorText} = useFormField(props)

    let selectOptions
    if (Array.isArray(options)) {
        if (typeof options[0] == 'string') {
            selectOptions = options.map(option => ({[selectLabel]: option, [selectValue]: option}))
        } else {
            selectOptions = options
        }
    } else {
        selectOptions = Object.entries(options).map((kv) => ({[selectValue]: kv[0], [selectLabel]: kv[1]}))
    }

    // console.log("selectOptions", selectOptions)

    const selectedOption = value && selectOptions.find(x => x[selectValue]?.toString() === value?.toString())

    if (allowOther) {
        return (
            <Autocomplete
                sx={{backgroundColor: "white"}}
                disableClearable={!isClearable}
                size="small"
                options={selectOptions}
                fullWidth={true}
                freeSolo={allowOther}
                blurOnSelect={true}
                getOptionLabel={option => option[selectLabel] || ''}
                renderInput={(params) =>
                    <TextField {...params}
                               label={label}
                               error={hasError}
                               helperText={hasError && errorText}/>
                }
                value={selectedOption || null}
                onChange={(x, value) => {
                    return setValue(value ? value[selectValue] : null)
                }
                }
            />
        )
    } else {
        return (<FormControl size="small" fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                sx={{backgroundColor: "white", color: "black"}}
                label={label}
                fullWidth={true}
                value={selectedOption ? value : ''}
                onChange={(e) => {
                    return setValue(e.target.value)
                }}
            >
                {selectOptions.map(o => <MenuItem key={o[selectValue]}
                                                  value={o[selectValue]}>{o[selectLabel]}</MenuItem>)}
            </Select>
            {hasError && <FormHelperText error={true}>{errorText}</FormHelperText>}
        </FormControl>)
    }
}

export default FormFieldSelect
