export interface CurrentEnvType {
    revision: string
    env: string
    date: string
    apiUrl: string
    redirectUrl: string
}

const currentEnv:CurrentEnvType = {
    revision:process.env.REACT_APP_REVISION || '',
    env:process.env.REACT_APP_ENV || '',
    date:process.env.REACT_APP_DATE || '',
    apiUrl:process.env.REACT_APP_API_URL || '',
    redirectUrl:process.env.REACT_APP_REDIRECT_URL || '',
}
export default currentEnv