import Typography from "@mui/material/Typography";
import * as React from "react";
import {ReactElement} from "react";
import {Stack} from "@mui/material";
import {useBookingContext} from "../../../context/BookingContext";

interface ItemProps {
    label: string
    value1?: string | number | ReactElement
    value2?: string | number | ReactElement
    noBoldValue1WhenBookingProcessed?: boolean
}

export default function Item({label, value1, value2, noBoldValue1WhenBookingProcessed}: ItemProps) {
    const {booking} = useBookingContext()
    const bookingProcessed = !!booking.id

    return (
        <Stack sx={{mb:1}}>
            <Typography sx={{fontWeight: 'bold', color: bookingProcessed ? undefined : '#1e5e9d'}}>{label}</Typography>
            {value1 && <Typography sx={bookingProcessed && noBoldValue1WhenBookingProcessed ? {} : {fontWeight: 'bold'}}>{value1}</Typography>}
            {value2 && <Typography>{value2}</Typography>}
        </Stack>
    );
}