import Typography from "@mui/material/Typography";
import * as React from "react";
import {Button, Stack} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import {MouseEventHandler, ReactNode} from "react";
export enum Status {
    COMPLETED = "COMPLETED",
    ACTIVE = "ACTIVE",
    PENDING = "PENDING"
}

interface BookingFlowStepProps {
    label: string
    number: number
    status: Status
    onClick: MouseEventHandler<HTMLButtonElement> | undefined
}

export default function BookingFlowStep({label, number, status, onClick}: BookingFlowStepProps) {
    let bgColor
    let buttonLabel:ReactNode = `${number}`
    switch (status) {
        case Status.PENDING: bgColor = "#d3d3d3"; break;
        case Status.ACTIVE: bgColor = "#1268b3"; break;
        case Status.COMPLETED: bgColor = "#162b48"; buttonLabel = <CheckIcon/>; break;
    }
    return (
        <Stack sx={{width:"100%", alignItems:'center'}}>
            <Button sx={{borderRadius:0, backgroundColor:bgColor, width:"100%", height:"1.2em", fontSize:'1.5em', fontWeight:400}} variant="contained" onClick={onClick}>{buttonLabel}</Button>
            <Typography sx={{textTransform: "uppercase", color: bgColor}}>{label}</Typography>
        </Stack>
    );
}