import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Stack} from "@mui/material";
import FormFieldSelect from "../form/FormFieldSelect";
import FormFieldDate from "../form/FormFieldDate";
import {useBookingContext} from "../../context/BookingContext";
import dayjs from "dayjs";
import {useReferenceContext} from "../../context/ReferenceContext";
import Env from "../../utils/Env";
import {useSearchParams} from "react-router-dom";
import {toDateString, toDateTimeString} from "../../utils/DateUtils";

interface BookingStripFormProps {
    external?: boolean
    direction?: 'row' | 'column'
}

const getNextValidPickupDate = () => {
    let now = dayjs()
    now = now = dayjs().add(15 - (now.get('minute') % 15), 'minutes').startOf('minute');
    if (now.hour() > 17) {
        now.add(1, 'day').set('hour', 9)
    }
    while([0,6].includes(now.day())) {
        now = now.add(1, 'day')
    }
    return now
}

export default function BookingStripForm({external = false, direction = 'row'}: BookingStripFormProps) {
    const {reference} = useReferenceContext()
    const {booking, updateBooking, isValidForFields} = useBookingContext()

    const [search] = useSearchParams();

    const startDate = booking.start_date
    const endDate = booking.end_date

    const [submitCount, setSubmitCount] = useState(0)

    const showErrors = submitCount > 0 || !external

    const valid = isValidForFields(['location_id', 'start_date', 'end_date', 'vehicle_category_id', 'hirer_age_id'])

    function submitForm() {
        setSubmitCount(sc => sc + 1)
        if (valid) {
            if (window.top && external) {
                window.top.location.href = search.get('url') || Env.redirectUrl
            }
        }
    }

    useEffect(() => {
        const nextValidPickupDate = dayjs(getNextValidPickupDate())
        let newStartDate
        if (startDate && dayjs(startDate).isBefore(nextValidPickupDate)) {
            newStartDate = nextValidPickupDate
            updateBooking({"start_date": toDateTimeString(nextValidPickupDate)})
        } else if (startDate) {
            newStartDate = dayjs(startDate)
        }
        if (newStartDate && (!endDate || dayjs(endDate).diff(newStartDate.startOf('day'), 'days') < 30)) {
            updateBooking({"end_date": toDateString(newStartDate.add(30, 'days'))})
        }
    }, [startDate, endDate, updateBooking])

    return (
        <Stack direction={direction} spacing={2} justifyContent="center">
            <FormFieldSelect name="location_id" label="Pick Up Location" options={reference.locations}
                             showErrors={showErrors}/>
            <FormFieldDate type="datetime" label="Pick Up Date" name="start_date" weekdaysOnly={true}
                           workhoursOnly={true} minDate={getNextValidPickupDate()} showErrors={showErrors}/>
            <FormFieldDate type="date" label="Drop Off Date" name="end_date" weekdaysOnly={true} workhoursOnly={true}
                           minDate={dayjs(booking.start_date || getNextValidPickupDate()).add(30, 'days')} showErrors={showErrors}/>
            <FormFieldSelect label="Vehicle Category" name="vehicle_category_id" options={reference.vehicle_categories}
                             showErrors={showErrors}/>
            <FormFieldSelect label="Driver Age" name="hirer_age_id" options={reference.hirer_ages}
                             showErrors={showErrors}/>
            {external && <Button
                endIcon={">"}
                sx={{
                    backgroundColor: '#071423',
                    justifyContent: 'left',
                    fontSize: direction === 'column' ? "1.15rem" : undefined,
                    minWidth: '7rem',
                    pl: 3,
                    "& .MuiButton-endIcon": {
                        position: "absolute",
                        right: "1rem",
                    },
                }}
                variant="contained"
                disabled={showErrors && !valid}
                onClick={submitForm}
            >Search</Button>}
        </Stack>
    );
}