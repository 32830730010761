import * as Yup from 'yup'
import 'utils/YupHelpers'
import dayjs from "dayjs";

const BookingSchema = Yup.object({
    'location_id': Yup.number().nullable().required(),
    'hirer_age_id': Yup.number().nullable().required(),
    'vehicle_category_id': Yup.number().nullable().required(),
    'start_date': Yup.date().nullable().required().test('business_hours', (x) => {
        const time = dayjs(x.value).format("h:mma")
        return `Invalid time: ${time}.  Hour must be between 9am and 5pm`
    }, (value) => {
        const hour = dayjs(value).hour()
        return hour >= 9 && hour <= 17
    }),
    'end_date': Yup.date().nullable().required(),
    'title': Yup.string().nullable().required(),
    'first_name': Yup.string().nullable().required(),
    'last_name': Yup.string().nullable().required(),
    'address': Yup.string().nullable().required(),
    'suburb': Yup.string().nullable().required(),
    'state': Yup.string().nullable().required(),
    'postcode': Yup.string().required().matches(/^[0-9]+$/, "Must be only digits")
        .min(4, 'Must be exactly 4 digits')
        .max(4, 'Must be exactly 4 digits'),
    'mobile': Yup.string().nullable().required(),
    'email': Yup.string().email().nullable().required(),
    'dob': Yup.date().nullable().required(),
})

export default BookingSchema
