import {createTheme} from '@mui/material/styles';
import {red} from '@mui/material/colors';

const defaultTheme = createTheme()
let theme = createTheme({
    typography: {
        fontFamily: 'Poppins,Arial',
        allVariants: {
            color: '#071423'
        }
    },
    palette: {
        primary: {
            main: '#071423',
            dark: '#111111'
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: red.A400,
        },
    },
    components: {
        // MuiStepper: {
        //     styleOverrides: {
        //         root: {
        //             transform: 'scale(1.1)'
        //         }
        //     }
        // },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            }
        },
        MuiButton: {
            // defaultProps: {
            //     disableElevation: true
            // },
            styleOverrides: {
                root : {
                    textTransform: "inherit",
                    paddingLeft: defaultTheme.spacing(3),
                    paddingRight: defaultTheme.spacing(3),
                    boxShadow: 'none'
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    textTransform: "inherit",
                    paddingLeft: defaultTheme.spacing(3),
                    paddingRight: defaultTheme.spacing(3),
                }
            }
        },
        MuiStepLabel: {
            styleOverrides: {
                iconContainer: {
                    transform: 'scale(1.4)',
                },
            }
        }
    }
});

export default theme;