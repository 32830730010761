import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {ThemeProvider} from "@mui/material";
import theme from './theme';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import routes from "./routes";
import BookingContextProvider from "./context/BookingContext";
import ReferenceContextProvider from "./context/ReferenceContext";
import AlertMessageContextProvider from "./context/AlertMessageContext";
import * as Sentry from '@sentry/react';
import {BrowserTracing} from "@sentry/tracing";
import Env from "./utils/Env";

if (Env.env !== 'local') {
    Sentry.init({
        dsn: "https://a8adfde3ecf9486f9d5a25b691a6482b@sentry.refactor.com.au/17",
        integrations: [new BrowserTracing()],
        release: 'ef-public-frontend@' + Env.revision,
        environment: Env.env,
    });
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter(routes);

root.render(
    // TODO Disabled StrictMode to prevent error showing DOB on react-datepicker
    // <React.StrictMode>
    <ThemeProvider theme={theme}>
        <AlertMessageContextProvider>
            <ReferenceContextProvider>
                <BookingContextProvider>
                    <RouterProvider router={router}/>
                </BookingContextProvider>
            </ReferenceContextProvider>
        </AlertMessageContextProvider>
    </ThemeProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
