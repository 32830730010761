import * as React from "react";
import {FlowStepProps} from "../BookingFlow";
import {Box, Typography} from "@mui/material";
import PersonalDetailsForm from "./PersonalDetailsForm";

export default function PersonalDetails({onNextPage}: FlowStepProps) {
    return (
        <Box>
            <Typography variant="h5" sx={{mb:2}}>
                Your Details
            </Typography>
            <PersonalDetailsForm onSubmit={onNextPage}/>
        </Box>
    );
}