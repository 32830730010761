import React from "react";
import Container from "@mui/material/Container";
import PropertyList from "../components/PropertyList";
import Env from "../utils/Env";
import PropertyListRow from "../components/PropertyListRow";
import {Button} from "@mui/material";

export default function About() {
    return <Container maxWidth="lg" sx={{my: 4}}>
        <PropertyList>
            <PropertyListRow label="Environment">{Env.env}</PropertyListRow>
            <PropertyListRow label="Revision">{Env.revision}</PropertyListRow>
            <PropertyListRow label="Date">{Env.date}</PropertyListRow>
            <PropertyListRow label="API URL">{Env.apiUrl}</PropertyListRow>
            <PropertyListRow label="Redirect URL">{Env.redirectUrl}</PropertyListRow>
        </PropertyList>
        <Button onClick={()=>{throw new Error("Aargh!")}}>Fail</Button>
    </Container>
}