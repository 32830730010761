import dayjs from "dayjs";

export function toDateString(val: dayjs.ConfigType):string {
  return dayjs(val).format("YYYY-MM-DD")
}

export function toTimeString(val: dayjs.ConfigType):string {
  return dayjs(val).format("HH:mm:ss")
}

export function toDateTimeString(val: dayjs.ConfigType):string {
  return dayjs(val).format("YYYY-MM-DD HH:mm:ss")
}

export function toInstantString(val: dayjs.ConfigType):string {
  return dayjs(val).toISOString()
}
