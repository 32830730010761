import BookingStrip from "pages/BookingStrip";
import React from "react";
import {RouteObject} from "react-router-dom";
import BookingFlow from "pages/BookingFlow/BookingFlow";
import QuickQuote from "./pages/QuickQuote";
import About from "./pages/About";
import BookingBox from "./pages/BookingBox";

const routes:RouteObject[] = [
    {
        path: "/",
        element: <BookingFlow/>,
    },
    {
        path: "/embed",
        element: <BookingStrip/>,
    },
    {
        path: "/embed-box",
        element: <BookingBox/>,
    },
    {
        path: "/quick",
        element: <QuickQuote/>,
    },
    {
        path: "/about",
        element: <About/>,
    },
]

export default routes