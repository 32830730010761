import Typography from "@mui/material/Typography";
import * as React from "react";
import {Stack, styled, ToggleButton, ToggleButtonGroup} from "@mui/material";
import tick from "../../../components/VehicleBox/icons/tick.png";
import {useBookingContext} from "../../../context/BookingContext";
import {useReferenceContext} from "../../../context/ReferenceContext";


const SubscriptionPlanToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        borderRadius: '0',
        border: '0',
        color: 'white',
        backgroundColor: '#d3d3d3',
        '&.Mui-selected': {
            color: 'white',
            backgroundColor: '#f6f7f8'
        },
    },
}));

const SubscriptionPlanToggleButton = styled(ToggleButton, {shouldForwardProp: (prop) => prop !== 'selectedColor'})<{ selectedColor?: string }>(({ theme, selectedColor }) => ({
    '&.Mui-selected': {
        backgroundColor: `${selectedColor} !important`,
    },
    '&:hover': {
        backgroundColor: `${selectedColor} !important`,
    },
    '&:not(:first-of-type)': {
        marginLeft: '2px',
    },
    '&:not(:last-of-type)': {
        marginRight: '2px',
    },
}));

export default function SubscriptionPlanBlock() {
    const { booking, ...bookingContext } = useBookingContext()
    const selectedPlan = bookingContext.subscription_plan
    const { reference } = useReferenceContext()

    const planFeatures = selectedPlan ? [
        `${selectedPlan.included_km_per_week}km per week`,
        `${selectedPlan.min_days} days min term`,
        selectedPlan.swap_text,
        selectedPlan.wash_text,
    ]: []
    return (
        <Stack sx={{border:"1px solid #d3d3d3", borderTop: 0}}>
            <SubscriptionPlanToggleButtonGroup
                sx={{mb:1}}
                size="small"
                color="primary"
                value={booking.subscription_plan_id}
                exclusive
                onChange={(e,v)=> {
                    if (v) {
                        bookingContext.updateBooking({subscription_plan_id:v})
                    }
                }}
                aria-label="Plan"
            >
                {reference.subscription_plans.map(p =>
                    <SubscriptionPlanToggleButton selectedColor={p.colour} disabled={!bookingContext.validPlanIds.includes(p.id)} key={p.id} value={p.id}>{p.name}</SubscriptionPlanToggleButton>
                )}
            </SubscriptionPlanToggleButtonGroup>
            {booking.subscription_plan_id && <Stack sx={{ml:2, mb:1}}>
                {planFeatures.map((f,i) => <Typography key={i} variant="body2"><img alt="Tick" src={tick} width={23} height={23}/>{f}</Typography> )}
            </Stack>}
        </Stack>
    );
}