import * as React from "react";
import {useReferenceContext} from "../../../context/ReferenceContext";
import {
    Checkbox,
    MenuItem,
    Paper,
    Select, Stack,
    Table,
    TableBody,
    TableCell, tableCellClasses,
    TableContainer,
    TableHead,
    TableRow, Tooltip, Typography
} from "@mui/material";
import {usePriceCalculator} from "../../../utils/usePriceCalculator";
import {useBookingContext} from "../../../context/BookingContext";
import {VehicleExtra, VehicleExtraCategory} from "../../../generated/Api";
import {displayMoney} from "../../../utils/DisplayUtils";
import Box from "@mui/material/Box";
import InfoIcon from '@mui/icons-material/Info';

export interface VehicleOptionsFormProps {
    vehicleExtraCategory:VehicleExtraCategory
}
export default function VehicleOptionsForm({vehicleExtraCategory}:VehicleOptionsFormProps) {
    const {reference} = useReferenceContext()
    const {booking, updateBooking} = useBookingContext()

    const priceCalculator = usePriceCalculator()


    function updateExtra(extra: VehicleExtra, count: number) {
        let extras = (booking.extras || []).filter(e => e.vehicle_extra_id !== extra.id)
        if (count) {
            extras = [...extras, {vehicle_extra_id: extra.id, count: count}]
        }
        updateBooking({extras})
    }

    return (
        <TableContainer component={Paper} sx={{mb:2}}>
            <Table size="small" sx={{
                minWidth: 650,
                [`& .${tableCellClasses.root}`]: { borderBottom: "none"}
            }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{width:'40%'}}>{vehicleExtraCategory.name}</TableCell>
                        <TableCell sx={{width:'20%'}}>Weekly Price</TableCell>
                        <TableCell sx={{width:'10%'}}>Selected</TableCell>
                        <TableCell sx={{width:'15%', textAlign:"right"}}>Total Weekly Price</TableCell>
                        <TableCell sx={{width:'15%', textAlign:"right"}}>Total Cost</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {reference.vehicle_extras.filter(e => e.vehicle_extra_category_id === vehicleExtraCategory.id).map((extra, i) => {
                        const currentCount = booking.extras?.find(e => e.vehicle_extra_id === extra.id)?.count || 0
                        const {individual_weekly_price, weekly_price, weekly_discount,  total_price, max_count} = priceCalculator.getExtraPrice(extra, currentCount)
                        return (<TableRow
                            key={extra.id}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell>
                                <Stack direction="row" alignItems="center">
                                    <Box component="img" sx={{width:'30px', height:'30px', m:1}}  src={`/public/extras/${extra.image_code}.png`}/>
                                    {extra.name}
                                    <Tooltip title={extra.description}>
                                        <InfoIcon sx={{width: '0.7em', color:'#999'}}/>
                                    </Tooltip>
                                </Stack>
                            </TableCell>
                            <TableCell>
                                {weekly_discount ?
                                    <Typography component="span" variant="body2" sx={{m: 1, textDecoration: "line-through"}}>
                                        {displayMoney(individual_weekly_price + weekly_discount)}
                                    </Typography>
                                    : null}
                                <Typography component="span" variant="body2" sx={{color: weekly_discount ? "#da1c5c" : null}}>
                                    {displayMoney(individual_weekly_price)}
                                </Typography>
                                {!!max_count && <Typography component="span" variant="body2" sx={{ml:1}}>
                                    (max {max_count})
                                </Typography>}
                            </TableCell>
                            <TableCell>
                                {extra.multiple ?
                                    <Select size="small" name={`extra.${i}.count`} value={currentCount}
                                            onChange={(e) => updateExtra(extra, +e.target.value)}>
                                        <MenuItem value={0}>0</MenuItem>
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                    </Select>
                                    :
                                    <Checkbox name={`extra.${i}.selected`} checked={currentCount > 0} onChange={(e) => {
                                        const newCount = (e.target.checked) ? 1 : 0
                                        updateExtra(extra, newCount)
                                    }}/>
                                }
                            </TableCell>
                            <TableCell sx={{textAlign:"right"}}>{currentCount ? displayMoney(weekly_price) : null}</TableCell>
                            <TableCell sx={{textAlign:"right"}}>{currentCount ? displayMoney(total_price) : null}</TableCell>
                        </TableRow>)
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}