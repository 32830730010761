import * as React from 'react'
import {PropsWithChildren, useContext, useEffect, useState} from 'react'
import {Reference} from "../generated/Api";
import api from "../utils/api";

interface ReferenceContextType {
  reference: Reference
  refresh: ()=>void
}

export const ReferenceContext = React.createContext<ReferenceContextType>(undefined!)

export function useReferenceContext() {
  return useContext(ReferenceContext)
}

const ReferenceContextProvider = (props : PropsWithChildren<{}>) => {
  const [reference, setReference] = useState<Reference>({
    hirer_ages: [],
    subscription_hire_durations: [],
    subscription_plans: [],
    vehicle_ages: [],
    vehicle_categories: [],
    vehicle_extra_categories: [],
    vehicle_extras: [],
    vehicle_overrides: [],
    vehicle_subcategories: [],
    vehicle_types: [],
    locations: [],
  })
  const [refreshCount, setRefreshCount] = useState(0)

  useEffect( () => {
    api.reference.getReference().then(r => setReference(r.data))
  }, [refreshCount])

  function refresh() {
    setRefreshCount(rc => rc + 1)
  }

  const context = {reference, refresh}

  return (
    <ReferenceContext.Provider value={context}>
      {props.children}
    </ReferenceContext.Provider>
  )
}

export default ReferenceContextProvider
