import React, {ReactNode, useRef} from 'react'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "./react-datepicker-overrides.css"
import useFormField, {UseFormFieldProps} from 'components/form/useFormField'
import {Portal, TextField} from "@mui/material";
import dayjs, {Dayjs} from "dayjs";
import {toDateString, toDateTimeString, toTimeString} from "../../utils/DateUtils";

interface FormFieldDateProps extends UseFormFieldProps {
    label?: string
    type: string
    disabled?: boolean
    minDate?: string|null|Dayjs
    maxDate?: string|null|Dayjs
    weekdaysOnly?: boolean
    workhoursOnly?: boolean
}

const FormFieldDate = (props: FormFieldDateProps) => {
    const {
        name,
        label,
        type,
        disabled,
        minDate,
        maxDate,
        weekdaysOnly,
        workhoursOnly
    } = props

    const {value, setValue, hasError, errorText} = useFormField(props)

    const datePickerRef = useRef<DatePicker>(null)

    const showTime = ['datetime', 'time'].includes(type)
    const timeOnly = type === 'time'
    const dateOnly = ['date','dob'].includes(type)
    const valueAsDate = value ? timeOnly ? dayjs(value, ['HH:mm:ss']) : dayjs(value) : null

    const CalendarContainer = ({children}: { children: ReactNode[] }): ReactNode => {
        return (<Portal container={document.body}>{children}</Portal>)
    }

    const isWeekday = (date: Date) => {
        const day = date.getDay()
        return day !== 0 && day !== 6;
    };

    const isWorkHour = (date: Date) => {
        return date.getHours() >= 9 && date.getHours() <= 17 && (!minDate || dayjs(date).isAfter(minDate))
    }

    const dobOptions = type === "dob" ? {
        showYearDropdown:true,
        dateFormatCalendar:"MMMM",
        yearDropdownItemNumber:80,
        scrollableYearDropdown:true
    } : {}

    return <DatePicker ref={datePickerRef}
                       disabled={disabled}
                       selected={valueAsDate?.toDate() || null}
                       onChange={(date) => {
                           if (date == null) {
                               setValue(null)
                           } else {
                               const isoDate = timeOnly ? toTimeString(date) : dateOnly ? toDateString(date) : toDateTimeString(date)
                               setValue(isoDate)
                           }
                       }}
                       onKeyDown={(e) => {
                           if (e.key === 'Tab') {
                               if (datePickerRef.current) {
                                   datePickerRef.current.setOpen(false)
                               }
                           }
                       }}
                       id={`input-${name}`}
                       name={name}
                       popperContainer={CalendarContainer}
                       showTimeSelect={showTime}
                       showTimeSelectOnly={timeOnly}
                       dateFormat={timeOnly ? "h:mma" : "dd/MM/yyyy" + (showTime ? ' h:mma' : '')}
                       timeFormat="h:mma"
                       {...dobOptions}
                       timeIntervals={15}
                       filterDate={weekdaysOnly ? isWeekday : undefined}
                       filterTime={workhoursOnly ? isWorkHour : undefined}
                       minDate={minDate ? dayjs(minDate).toDate() : null}
                       maxDate={maxDate ? dayjs(maxDate).toDate() : null}
                       customInput={
                           <TextField
                               sx={{backgroundColor:"white"}}
                               size="small"
                               fullWidth={true}
                               label={label}
                               name={name}
                               helperText={hasError ? errorText : null}
                               error={hasError}
                               inputProps={{
                                   readOnly: true,
                               }}
                               variant="outlined"
                           />
                       }
    />
}

export default FormFieldDate
