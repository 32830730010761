import * as React from "react";
import {Stack, useMediaQuery, useTheme} from "@mui/material";
import {useBookingContext} from "../../../context/BookingContext";
import VehicleBox from "../../../components/VehicleBox/VehicleBox";
import {FlowStepProps} from "../BookingFlow";
import {useReferenceContext} from "../../../context/ReferenceContext";
import {usePriceCalculator} from "../../../utils/usePriceCalculator";
import SubscriptionPlanBlock from "./SubscriptionPlanBlock";
import VehicleAgeBlock from "./VehicleAgeBlock";

export default function PlanOptions({onNextPage}:FlowStepProps) {
    const { reference } = useReferenceContext()
    const { booking, ...bookingContext } = useBookingContext()

    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));


    const priceCalculator = usePriceCalculator()

    const visibleVehicles = reference.vehicle_types.map(vt => ({vehicle_type:vt, price: priceCalculator.getVehiclePrice(vt)})).filter(x =>
        x.vehicle_type.vehicle_category_id === booking.vehicle_category_id &&
        x.price.available &&
        x.vehicle_type.base_weekly_price > 0
    ).sort((x1,x2) => x1.price.weekly_price - x2.price.weekly_price)

    return (
        <Stack spacing={2}>
        <Stack sx={{mb:2.5}} direction={mobileDevice ? "column" : "row"} spacing={2} justifyContent="space-between" alignItems="flex-start">
            <SubscriptionPlanBlock/>
            <VehicleAgeBlock/>
        </Stack>
            {visibleVehicles.map(x => <VehicleBox key={x.vehicle_type.id} vehicle={x.vehicle_type} vehiclePrice={x.price} days={priceCalculator.days} onSelect={()=> {
                bookingContext.updateBooking({vehicle_type_id:x.vehicle_type.id})
                onNextPage()
            }}/>)}
        </Stack>
    );
}